import React, { useEffect, useState, Fragment } from 'react';
import './HomeGames.css'
import data from '../../JSON/gameData.json'
import GameCard from '../GameCard/GameCard';
import { scrollToTop } from '../../utility/utility';
import { FaArrowCircleUp } from "react-icons/fa";
import AdComponent from '../AdComponent/AdComponent';

function HomeGames() {
    return (
        <>

            <div className="scrollTop cursor-pointer" onClick={() => scrollToTop()}><FaArrowCircleUp size={30} color="rgb(46 210 214)" /></div>
            <div className='bgContent'>
                <div className='main-container'>
                <div className='row m-0 g-1'>
                        {
                            data.map((element, index) => {
                                return <Fragment key={index}>
                            { (index == 18 || index == 36) && 
                                <div className='col-12'>
                                    <AdComponent
                                        client="ca-pub-3550658214779566"
                                        slot="9314331048"
                                        format="auto"
                                        style={{ display: 'block' }}
                                        />
                                </div>
                            }
                            <div key={index} className='col-6 col-sm-4 col-lg-3 col-xl-2 p-2'>
                                    <GameCard data={element} />
                                </div>
                        </Fragment>
                                  
                            })
                        }
                    </div>
                    {/* {<AdComponent
                        client="ca-pub-3550658214779566"
                        slot="3736715081"
                        format="autorelaxed"
                        style={{ display: 'block' }}
                    />} */}


                    <section className='p-3 rounded' style={{ backgroundColor: '#353535', color: 'rgba(255, 255, 255, 0.623)' }}>
                        <div className='h6'>Welcome to MultiGameZone: Your Ultimate Free Gaming Hub!</div>

                        <p>Explore boundless fun and excitement at MultiGameZone, your premier destination for free online games. Whether you're a casual player looking to unwind or a hardcore gamer seeking your next challenge, we've got something for everyone!</p>

                        <div className='h4'>Game Categories:</div>
                        <ul>
                            <li><b className='fw-bold'>Adventure</b>: Embark on epic adventures and unravel captivating storylines.</li>
                            <li><b className='fw-bold'>Puzzle</b>: Sharpen your mind with brain-teasing puzzles and logic games.</li>
                            <li><b className='fw-bold'>Arcade</b>: Rediscover the classics or uncover new arcade hits.</li>
                            <li><b className='fw-bold'>Action</b>: Challenge your reflexes and strategy with heart-pounding action games.</li>
                            <li><b className='fw-bold'>Sports</b>: Immerse yourself in the game with our array of sports simulations.</li>
                            <li><b className='fw-bold'>Multiplayer</b>: Connect with friends or players from around the world for competitive gaming fun.</li>
                        </ul>
                        <div className='h4'>Why Choose MultiGameZone?</div>
                        <ul>
                            <li><b className='fw-bold'>
                                Free Online Play</b>: Enjoy thousands of games without any cost.</li>
                            <li><b className='fw-bold'>No Downloads Required</b>: Play instantly from your browser.</li>
                            <li><b className='fw-bold'>Regular Updates</b>: New games added every week to keep the fun going.</li>
                            <li><b className='fw-bold'>User-Friendly Interface</b>: Easy navigation to find and play your favorite games.</li>
                            <li><b className='fw-bold'>Community Features</b>: Join our community to share tips, reviews, and high scores.</li>
                        </ul>
                        <div className='h4'>Get Started:</div>
                        <ul>
                            <li><b className='fw-bold'>Browse</b>: Explore our vast collection of games.</li>
                            <li><b className='fw-bold'>Play</b>: Click on a game to start playing immediately.</li>
                            <li><b className='fw-bold'>Enjoy</b>: Have fun and don't forget to bookmark MultiGameZone for easy access.</li>

                        </ul>
                        <p> Join millions of players worldwide and embark on your gaming adventure with MultiGameZone today! </p>
                    </section>
                </div>
            </div>
        </>
    )
}

export default HomeGames