import React, { useEffect } from 'react'
import HomeGames from '../../Components/HomeGames/HomeGames'
import ViewMaster from '../../Components/ViewMaster/ViewMaster'

function Home() {
  useEffect(() => {
    document.title = "Multi Game Zone";
  }, [])
  return (
    <>
        <div>
            <HomeGames />
        </div>
    </>
  )
}

export default ViewMaster(Home)