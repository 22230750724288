import React from 'react';
import './Footer.css';
import logo from '../../assets/images/logo.png';
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import gameData from '../../JSON/gameData.json'

function Footer() {
    const games = gameData?.slice(0, 10)
    return (
        <div className='footer'>
            <div className='main-container h-100'>
                <div className='row m-0 h-100 d-flex py-3'>
                    <div className='col-12 col-md-2'>
                        <img src={logo} alt="" width={'100%'} style={{ maxWidth: '200px' }} />
                    </div>

                    <div className='col-10 d-flex flex-wrap gap-5 footerLinks'>
                        <div className="d-flex flex-column">
                            <h6>Navigate</h6>
                            <div className='menuLinks mt-3'>
                                <a href="/">Home</a>
                                <a href="/aboutus">About Us</a>
                                <a href="/contactus">Contact Us</a>
                                <a href="/privacy-policy">Privacy</a>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <h6 className='d-block text-start w-100'>Games</h6>
                            <div className='menuLinks mt-3 d-flex gap-4'>
                                <div>
                                    {
                                        gameData?.slice(0, 5).map((game) => {
                                            return <a key={game.pageurlname} title={game.pageurlname} href={`/game/${game.pageurlname}`} className='text-nowrap'>{game.title}</a>
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        gameData?.slice(5, 10).map((game) => {
                                            return <a key={game.pageurlname} title={game.pageurlname} href={`/game/${game.pageurlname}`} className='text-nowrap'>{game.title}</a>
                                        })
                                    }
                                </div>
                                {/* <div>
                                    {
                                        gameData?.slice(10, 15).map((game) => {
                                            return <Link key={game.pageurlname} title={game.pageurlname} to={`/game/${game.pageurlname}`} className='text-nowrap'>{game.title}</Link>
                                        })
                                    }
                                </div> */}
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <h6>Get in touch</h6>
                            <div className='d-flex mt-3'>
                                <a href="https://www.facebook.com/share/Y1a4uGYSHegy1Fhd/?mibextid=qi2Omg" target='_blank' style={{ color: '#0866ff' }}><FaFacebookSquare size={20} /></a>
                                <a href="https://www.instagram.com/multi_gamezone?igsh=MWNzZHYzNWMwbHN4dw==" target='_blank' style={{ color: '#e41beb' }}><FaInstagram className='ms-3' size={20} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
