import React from 'react';
import './GameCard.css'
import { Link } from 'react-router-dom';

function GameCard({ data }) {

  return (
    <>
      <div className='gameCard'>
        <a href={`/game/${data.pageurlname}`}><img src={require(`../../assets/images/${data.logo}`)} alt={"Multi Game Zone - " + data.title} />
          <div className="databg"></div>
          <p className='text-center m-0 fs-16 king'>{data.title}</p>
        </a>

      </div>
    </>
  )
}

export default GameCard